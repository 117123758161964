// @flow

import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import {
  Modal,
  Box,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Grid
} from '@mui/material';
import classNames from 'classnames';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import { showToastMsg } from 'features/toast-message-slice';
import AppLoadingSpinner from 'layout/AppLoadingSpinner';
import { setIsLoading } from 'features/loading-slice';
import {
  useTryGetNewChartFilesMutation,
  useUploadChartFilesMutation,
  useUploadToPatientMutation
} from 'api/api';

const baseStyle = {
  flex: '0 1 auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '10% 20%',
  margin: '0px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const activeStyle = {
  borderColor: '#2196f3' // Blue border when active
};

const acceptStyle = {
  borderColor: '#00e676' // Green border when accepted
};

const rejectStyle = {
  borderColor: '#ff1744' // Red border when rejected
};

type Props = {
  open: boolean,
  onClose: () => void,
  patientId: string,
  refetch: () => void
};

const ChartFilesUploadModal = ({
  open,
  onClose,
  patientId,
  refetch
}: Props) => {
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);
  const [uploadingFiles, setUploadingFiles] = useState(new Set());
  const [isUploading, setIsUploading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);

  const [uploadChartFiles] = useUploadChartFilesMutation();
  const [uploadToPatient] = useUploadToPatientMutation();
  const [tryGetNewChartFiles] = useTryGetNewChartFilesMutation();

  const onDrop = useCallback(
    (acceptedFiles) => {
      const updatedFiles = acceptedFiles
        .filter((file) => {
          return (
            !files.some(
              (existingFile) => existingFile.fileName === file.name
            ) && !uploadingFiles.has(file.name)
          );
        })
        .map((file) => ({
          file,
          fileName: file.name,
          mimeType: file.type,
          tempUrl: null
        }));

      if (updatedFiles.length > 0) {
        setUploadingFiles((prevUploadingFiles) => {
          const newSet = new Set(prevUploadingFiles);
          updatedFiles.forEach((file) => newSet.add(file.fileName));
          return newSet;
        });

        setFiles((prevFiles) => [...prevFiles, ...updatedFiles]);

        handleUpload(updatedFiles);
      }
    },
    [files, uploadingFiles]
  );

  const handleUpload = async (filesToUpload) => {
    if (filesToUpload.length === 0) {
      dispatch(
        showToastMsg({
          open: true,
          message: 'No files to upload.',
          level: 'warning',
          duration: 5000
        })
      );
      return;
    }

    setIsUploading(true);
    dispatch(setIsLoading({ loadingModule: 'fileUpload', isLoading: true }));

    try {
      const uploadedFiles = [];

      for (let i = 0; i < filesToUpload.length; i++) {
        const { file, fileName, mimeType } = filesToUpload[i];

        const response = await uploadChartFiles({
          patientId,
          filename: fileName
        }).unwrap();

        const { uploadUrl, fileId } = response;

        await axios.put(uploadUrl, file, {
          headers: {
            'Content-Type': mimeType
          }
        });

        const uploadedFile = {
          ...filesToUpload[i],
          tempUrl: uploadUrl,
          fileId
        };
        uploadedFiles.push(uploadedFile);

        setFiles((prevFiles) => {
          const newFiles = prevFiles.map((existingFile) =>
            existingFile.fileName === fileName ? uploadedFile : existingFile
          );
          return newFiles;
        });

        setUploadingFiles((prevUploadingFiles) => {
          const newSet = new Set(prevUploadingFiles);
          newSet.delete(fileName);
          return newSet;
        });
      }

      dispatch(
        showToastMsg({
          open: true,
          message: 'Files uploaded successfully. Ready to confirm.',
          level: 'success',
          duration: 5000
        })
      );
    } catch (error) {
      dispatch(
        showToastMsg({
          open: true,
          message: error.message || 'File upload failed.',
          level: 'error',
          duration: 5000
        })
      );
    } finally {
      setIsUploading(false);
      dispatch(setIsLoading({ loadingModule: '', isLoading: false }));
    }
  };

  const handleDeleteFile = async (fileIndex) => {
    setFiles(files.filter((_, i) => i !== fileIndex));
  };

  const handleTryGetNewChartFiles = async (retries) => {
    console.log('[tryGetNewChartFiles] retry: ', retries);
    await new Promise((resolve) => setTimeout(resolve, 5 * 1000)); // wait 5 seconds

    try {
      const { data } = await tryGetNewChartFiles({
        patientId,
        filenames: files.map((file) => file.fileName)
      });

      const { chartDocumentsExist, success } = data;

      console.log('[tryGetNewChartFiles] parsedBody: ', data);

      if (chartDocumentsExist && success) {
        refetch();
        dispatch(
          showToastMsg({
            open: true,
            message: 'Files uploaded successfully.',
            level: 'success',
            duration: 5000
          })
        );
        return chartDocumentsExist;
      } else if (!chartDocumentsExist && retries > 0) {
        return handleTryGetNewChartFiles(retries - 1);
      } else {
        throw new Error('Max number of attempt reached');
      }
    } catch (error) {
      console.log('[tryGetNewChartFiles] error: ', error);
      return null;
    }
  };

  const handleUploadToPatient = async () => {
    setIsConfirming(true);

    try {
      dispatch(
        setIsLoading({ loadingModule: 'uploadToPatient', isLoading: true })
      );

      // eslint-disable-next-line no-unused-vars
      const filesToSend = files.map(({ file, ...rest }) => rest);

      const response = await uploadToPatient({
        fileIds: filesToSend.map((file) => file.fileId)
      }).unwrap();

      if (response.success) {
        dispatch(
          showToastMsg({
            open: true,
            message:
              'Files being processed for move to the official storage and database. ' +
              'You can wait or close the modal and check later',
            level: 'success',
            duration: 5000
          })
        );

        handleTryGetNewChartFiles(60);
        setFiles([]);
        onClose();
      } else {
        dispatch(
          showToastMsg({
            open: true,
            message: response,
            level: 'error',
            duration: 5000
          })
        );
      }
    } catch (error) {
      dispatch(
        showToastMsg({
          open: true,
          message: 'Failed to upload charts to patient.',
          level: 'error',
          duration: 5000
        })
      );
    } finally {
      setIsConfirming(false);
      dispatch(setIsLoading({ loadingModule: '', isLoading: false }));
    }
  };

  const handleCancel = async () => {
    setIsDeleting(true);
    setFiles([]);
    setIsDeleting(false);
    dispatch(setIsLoading({ loadingModule: '', isLoading: false }));
    onClose();
  };

  useEffect(() => {
    if (!open) {
      handleCancel();
    }
  }, [open]);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    multiple: true,
    onDrop,
    accept: '.xml, .pdf, .txt, .html'
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <Modal open={open} onClose={handleCancel}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          display: 'grid',
          transform: 'translate(-50%, -50%)',
          maxWidth: 800,
          width: '100%',
          bgcolor: 'background.paper',
          border: '2px solid primary.main',
          boxShadow: 24,
          p: 4
        }}
      >
        {(isUploading || isDeleting || isConfirming) && <AppLoadingSpinner />}
        <Typography variant="h6" component="h2">
          Upload Chart Files
        </Typography>
        <Typography className="!my-2">
          Please verify patient identity match before uploading new files.
        </Typography>
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <Grid className="text-center">
            <Typography variant="h5">
              Drop .XML or .PDF files here, or click to upload.
            </Typography>
            <Typography variant="h6">
              (Only .XML and .PDF files are accepted)
            </Typography>
            <CloudUploadIcon sx={{ fontSize: 40, color: '#2196f3', mt: 2 }} />
          </Grid>
        </div>
        <List
          className={classNames(
            'h-[200px] !my-2 overflow-scroll shadow-[inset_0px_0px_3px_rgba(0,0,0,0.6)]'
          )}
        >
          {files.map((fileObj, index) => (
            <ListItem
              key={index}
              secondaryAction={
                <IconButton
                  edge="end"
                  onClick={() => handleDeleteFile(index)}
                  disabled={isDeleting || isUploading || isConfirming}
                >
                  <DeleteIcon
                    color={
                      isDeleting || isUploading || isConfirming
                        ? 'disabled'
                        : 'error'
                    }
                  />
                </IconButton>
              }
            >
              <ListItemText primary={fileObj.file.name} />
            </ListItem>
          ))}
        </List>
        <Button
          variant="contained"
          color="primary"
          className="mt-2 justify-self-end"
          onClick={handleUploadToPatient}
          disabled={
            files.length === 0 || isUploading || isDeleting || isConfirming
          }
        >
          Upload charts to patient
        </Button>
      </Box>
    </Modal>
  );
};

export default ChartFilesUploadModal;
