// @flow

import React, { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material//ArrowForwardIosSharp';
import SourceHistoryList from './SourceHistoryList';
import { useSelector } from 'react-redux';

type SourceHistoryProps = {
  sourceHistoryCount: Number,
  factName: String,
  hcc: String,
  patientId: String,
  classes: Object,
  setPdfViwerSrc: Function
};

const SourceHistory = (props: SourceHistoryProps) => {
  const {
    sourceHistoryCount,
    factName,
    hcc,
    classes,
    setPdfViwerSrc,
    patientId
  } = props;
  const [show, setShow] = useState(false);
  const currentNlpSource = useSelector(
    (state) => state.nlpSource.currentNlpSource
  );

  const formatFactName = (factName) => {
    if (currentNlpSource === 'IS_PDF') {
      return factName;
    }

    const [annotation, name, value] = factName.split('__');
    const parts = [annotation, name, value].filter(Boolean); // Remove any undefined or empty parts
    return parts.join(' | ');
  };

  return (
    <div>
      <div className={classes.sourceHistoryBtn} onClick={() => setShow(!show)}>
        <ExpandMoreIcon
          fontSize="10"
          style={{
            transform: show ? 'rotate(90deg)' : 'rotate(0)',
            marginRight: 5
          }}
        />{' '}
        {show ? 'Hide' : 'Reveal'} {sourceHistoryCount} other{' '}
        {sourceHistoryCount === 1 ? 'source' : 'sources'} for{' '}
        {formatFactName(factName)}
      </div>
      <div className={classes.sourceHistory}>
        {show ? (
          <SourceHistoryList
            factName={factName}
            hccId={hcc._id}
            patientId={patientId}
            classes={classes}
            setPdfViwerSrc={setPdfViwerSrc}
          />
        ) : null}
      </div>
    </div>
  );
};

export default SourceHistory;
