/* eslint-disable react/no-unknown-property */
// @flow
import React, { useState, useEffect, useMemo } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import GainSight from 'components/GainSight';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { StyledAppBar } from './Styles/_Header.Style';
import { useDispatch, useSelector } from 'react-redux';
import MDPBackend from 'services/MDPBackend';
import { log } from 'utils/jsUtils';
import { setOrganizations } from 'features/organizations-slice';
import { setCurrentOrganization } from 'features/currentOrganization-slice';
import { setIsUserHalOrganization } from 'features/halOrganization-slice';
import {
  LOGIN,
  UPLOAD_DEMOGRAPHICS,
  PATIENT_SEARCH,
  API_DOCS,
  EVENTS,
  QUERIES
} from 'settings/constants';
import { setLogout } from 'features/login-slice';
import {
  useGetOrganizationsQuery,
  useLazyGetIsUserHalBenchmarkOrgQuery
} from 'api/api';
import { showToastMsg } from 'features/toast-message-slice';
import { getHalBenchmarkOrgName } from '../halConstants';
import { Tab, Tabs } from '@mui/material';

const allPages = [
  { text: 'Upload Roster', route: UPLOAD_DEMOGRAPHICS },
  { text: 'Patients', route: PATIENT_SEARCH },
  { text: 'Queries', route: QUERIES },
  { text: 'Events', route: EVENTS },
  { text: 'API Docs', route: API_DOCS }
];

const helpMenuItems = [
  {
    text: 'I’m Having an Issue',
    url: 'https://reveleer.atlassian.net/servicedesk/customer/portal/7/group/21/create/73'
  },
  {
    text: 'Submit Feature Request',
    url: 'https://reveleer.atlassian.net/servicedesk/customer/portal/7/group/21/create/71'
  },
  {
    text: 'Track Your Tickets',
    url: 'https://reveleer.atlassian.net/servicedesk/customer/user/requests?page=1&reporter=all&statuses=open&statuses=closed'
  }
];

type Props = {
  isLoggedIn: Boolean,
  handleLogout: Function
};

const UserMenu = ({ isLoggedIn, handleLogout }: Props) => {
  const dispatch = useDispatch();
  const { data: organizationsData, error } = useGetOrganizationsQuery();
  const [getIsUserHalBenchmarkOrg] = useLazyGetIsUserHalBenchmarkOrgQuery();
  const { user } = useSelector((state) => state.user);
  const switchOrganization = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const organizationId = e.target.value;
    log('[switchOrganization] organizationId: ', organizationId);

    if (organizationId === 'Logout') {
      return;
    }

    try {
      const response = await MDPBackend.switchOrganization(organizationId);
      log('[switchOrganization] response: ', response);

      if (response.data.statusCode === 200) {
        document.location.reload();
      } else {
        dispatch({
          type: 'ACTION_SET_TOAST_MSG',
          snackMessage: {
            message: 'Something went wrong',
            level: 'error',
            duration: 5000
          }
        });
      }
    } catch (error) {
      log('[switchOrganization] error: ', error);
      dispatch({
        type: 'ACTION_SET_TOAST_MSG',
        snackMessage: {
          message: 'Something went wrong',
          level: 'error',
          duration: 5000
        }
      });
    }
  };

  useEffect(() => {
    const getAndSetIsUserHalBenchmark = async () => {
      if (user) {
        try {
          const response = await getIsUserHalBenchmarkOrg(user._id);

          console.log(
            'isUserHalBenchmarkOrg: ',
            response.data.isHalBenchmarkOrg
          );
          const isUserHalBenchmarkOrg = response.data.isHalBenchmarkOrg;

          let halOrgId;

          const HAL_BENCHMARK_ORG_NAME = getHalBenchmarkOrgName();

          const halOrganization = organizationsData.organizations.find(
            (org) => org.name === HAL_BENCHMARK_ORG_NAME
          );

          if (halOrganization) {
            halOrgId = halOrganization._id;
          } else {
            halOrgId = '';
          }

          const isCurrentOrgHalBenchmark =
            organizationsData.currentOrganization._id === halOrgId;

          dispatch(
            setIsUserHalOrganization(
              isUserHalBenchmarkOrg &&
                user.isReveleerTeam &&
                isCurrentOrgHalBenchmark
            )
          );
        } catch (err) {
          console.error('Error fetching isUserHalBenchmarkOrg: ', err);
        }
      }
    };

    if (organizationsData) {
      dispatch(setOrganizations(organizationsData.organizations));
      dispatch(setCurrentOrganization(organizationsData.currentOrganization));

      console.log('CURRENT ORG: ', organizationsData.currentOrganization);

      getAndSetIsUserHalBenchmark();
    }

    if (error) {
      dispatch(
        showToastMsg({
          open: true,
          message: error.message ? error.message : 'Network Error',
          level: 'error',
          duration: 5000
        })
      );
    }
  }, [organizationsData, error, user]);

  if (!organizationsData || !isLoggedIn) {
    return null;
  }

  return (
    <Box sx={{ flexGrow: 0 }}>
      <FormControl variant="standard">
        <Select
          value={organizationsData.currentOrganization._id || ''}
          onChange={switchOrganization}
          className="organizationSwitch"
        >
          {organizationsData.organizations.map((org, idx) => (
            <MenuItem key={idx} value={org._id}>
              {org.name}
            </MenuItem>
          ))}
          <MenuItem defaultValue="Logout" onClick={handleLogout}>
            Logout
          </MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const dispatch = useDispatch();

  const [helpMenuAnchorEl, setHelpMenuAnchorEl] = useState(null);

  const handleHelpMenuOpen = (event) => {
    setHelpMenuAnchorEl(event.currentTarget);
    setActiveTab(pages.length); // Set active tab to "Help"
  };

  const handleHelpMenuClose = () => {
    setHelpMenuAnchorEl(null);
    // Recompute active tab based on current route
    const activePage = pages.findIndex((page) => isActive(page.route));
    setActiveTab(activePage !== -1 ? activePage : false);
  };

  const [mobileHelpMenuAnchorEl, setMobileHelpMenuAnchorEl] = useState(null);
  const handleMobileHelpMenuOpen = (event) => {
    setMobileHelpMenuAnchorEl(event.currentTarget);
  };
  const handleMobileHelpMenuClose = () => {
    setMobileHelpMenuAnchorEl(null);
  };

  const handleLogout = async (e) => {
    e.preventDefault();
    try {
      await Auth.signOut({ global: true });

      dispatch(setLogout());
      navigate(LOGIN);
    } catch (error) {
      await Auth.signOut();

      dispatch(setLogout());
      navigate(LOGIN);
    }
  };

  const isActive = (route) => {
    return route === location.pathname;
  };

  const pages = useMemo(() => {
    return allPages.filter((page) => {
      // Check if the user's role is 'payer' and exclude 'Queries' page
      if (user?.role === 'payer' && page.text === 'Queries') {
        return false;
      }

      // Include the page if none of the above conditions apply
      return true;
    });
  }, [user]);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const [activeTab, setActiveTab] = useState(() => {
    const activePage = pages.findIndex((page) => isActive(page.route));
    return activePage !== -1 ? activePage : false;
  });

  const handleChange = (event, newValue) => {
    if (newValue === pages.length) {
      // This is the Help tab
      handleHelpMenuOpen(event);
      return;
    }
    setActiveTab(newValue);
    handleCloseNavMenu();
    navigate(pages[newValue].route);
  };

  const isAuthenticated = async () => {
    try {
      const currentSession = await Auth.currentSession();
      setIsLoggedIn(currentSession);
    } catch (error) {
      log('[privateRoute] Error: ', error);
    }
  };

  useEffect(() => {
    isAuthenticated();
  }, []);

  useEffect(() => {
    // Update activeTab when location changes
    const activePage = pages.findIndex((page) => isActive(page.route));
    if (helpMenuAnchorEl === null) {
      setActiveTab(activePage !== -1 ? activePage : false);
    }
  }, [location.pathname, helpMenuAnchorEl]);

  return (
    <StyledAppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Link to={isLoggedIn ? PATIENT_SEARCH : LOGIN}>
            <Paper
              variant="outlined"
              sx={{
                display: { xs: isLoggedIn ? 'none' : 'flex', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
                border: 'none'
              }}
            >
              <img
                className="h-[45px]"
                height={45}
                src="/assets/logo.svg"
                alt="Reveleer Logo"
              />
            </Paper>
          </Link>
          {isLoggedIn && (
            <>
              <Box
                sx={{
                  flexGrow: 1,
                  display: { xs: 'flex', md: 'none' }
                }}
              >
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon sx={{ color: 'black' }} />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: 'block', md: 'none' }
                  }}
                >
                  {pages.map((page, idx) => {
                    return (
                      <MenuItem
                        key={idx}
                        onClick={() => {
                          handleCloseNavMenu();
                          navigate(page.route);
                        }}
                        className="active"
                      >
                        <Typography textAlign="center">{page.text}</Typography>
                      </MenuItem>
                    );
                  })}
                  <MenuItem onClick={handleMobileHelpMenuOpen}>
                    <Typography textAlign="center">Help</Typography>
                  </MenuItem>
                  <Menu
                    id="mobile-help-menu"
                    anchorEl={mobileHelpMenuAnchorEl}
                    open={Boolean(mobileHelpMenuAnchorEl)}
                    onClose={handleMobileHelpMenuClose}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left'
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left'
                    }}
                  >
                    {helpMenuItems.map((item, idx) => (
                      <MenuItem
                        key={idx}
                        onClick={() => {
                          window.open(item.url, '_blank');
                          handleMobileHelpMenuClose();
                          handleCloseNavMenu();
                        }}
                      >
                        <Typography textAlign="center">{item.text}</Typography>
                      </MenuItem>
                    ))}
                  </Menu>
                </Menu>
              </Box>
              <Paper
                variant="outlined"
                sx={{
                  mr: 2,
                  display: { xs: 'flex', md: 'none' },
                  flexGrow: 1,
                  fontFamily: 'monospace',
                  fontWeight: 700,
                  letterSpacing: '.3rem',
                  color: 'inherit',
                  textDecoration: 'none',
                  border: 'none'
                }}
              >
                <img
                  className="h-[45px]"
                  height={45}
                  src="/assets/logo-mobile.svg"
                  alt="Reveleer Logo Mobile"
                />
              </Paper>
              <Box
                sx={{
                  width: '100%',
                  flexDirection: 'column',
                  display: { xs: 'none', md: 'flex' },
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                  {user && (
                    <>
                      <Tabs
                        value={activeTab}
                        onChange={handleChange}
                        aria-label="navigation tabs"
                      >
                        {pages.map((page, idx) => (
                          <Tab
                            key={idx}
                            component={Link}
                            to={page.route}
                            label={page.text}
                            className="menu-btn"
                            value={idx}
                          />
                        ))}
                        <Tab
                          label="Help"
                          aria-controls={
                            helpMenuAnchorEl ? 'help-menu' : undefined
                          }
                          aria-haspopup="true"
                          onClick={handleHelpMenuOpen}
                          className="menu-btn"
                          value={pages.length}
                        />
                      </Tabs>
                      <Menu
                        id="help-menu"
                        anchorEl={helpMenuAnchorEl}
                        open={Boolean(helpMenuAnchorEl)}
                        onClose={handleHelpMenuClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left'
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left'
                        }}
                      >
                        {helpMenuItems.map((item, idx) => (
                          <MenuItem
                            key={idx}
                            onClick={() => {
                              window.open(item.url, '_blank');
                              handleHelpMenuClose();
                            }}
                          >
                            {item.text}
                          </MenuItem>
                        ))}
                      </Menu>
                    </>
                  )}
                </Box>
              </Box>
              <UserMenu isLoggedIn={isLoggedIn} handleLogout={handleLogout} />
              <GainSight />
            </>
          )}
        </Toolbar>
      </Container>
    </StyledAppBar>
  );
};
export default Header;
