import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  open: false,
  message: '',
  level: 'success',
  duration: 3000
};

const toastMsgSlice = createSlice({
  name: 'toastMsg',
  initialState,
  reducers: {
    showToastMsg: (state, action) => {
      state.open = action.payload.open;
      state.message = action.payload.message;
      state.level = action.payload.level;
      state.duration = action.payload.duration;
    }
  }
});

export const { showToastMsg } = toastMsgSlice.actions;

export default toastMsgSlice.reducer;
