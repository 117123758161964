// @flow

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { Helmet } from 'react-helmet-async';
import queryString from 'query-string';
import { useForm, Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { withStyles } from 'tss-react/mui';
import PageTemplate from 'layout/PageTemplate';
import { useDispatch } from 'react-redux';
import { PATIENT_SEARCH, RESET_PASSWORD } from 'settings/constants';
import { emailRegex } from 'utils/jsUtils';
import { InputError } from 'components';
import styles from './Styles/Login.Styles';
import { setLogin } from 'features/login-slice';
import { showToastMsg } from 'features/toast-message-slice';
import { sanitizeRedirectOrURL } from '../../utils/jsUtils';

Auth.configure({
  cookieStorage: { domain: process.env.REACT_APP_DOMAIN }
});

type Props = {
  classes: Object,
  location: Object
};

const Login = (props: Props): React.Node => {
  const { classes } = props;
  // console.log('classes', classes);
  const location = useLocation();
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm({
    defaultValues: {
      email: '',
      password: ''
    }
  });

  const [loading, setLoading] = useState(false);

  /* eslint-disable-next-line no-unused-vars */
  const dispatch = useDispatch();

  const login = async (data) => {
    setLoading(true);

    try {
      const { email, password } = data;
      await Auth.signIn(email, password);
      dispatch(setLogin());

      const params = queryString.parse(location.search);

      if (params.redirect) {
        const safeRedirect = sanitizeRedirectOrURL(params.redirect);
        navigate(safeRedirect);
      } else {
        navigate(PATIENT_SEARCH);
      }
    } catch (e) {
      console.log(e);
      let message = 'Invalid user or password';

      if (e.code === 'UserNotConfirmedException') {
        message = 'Please confirm your email address';
      }

      dispatch(
        showToastMsg({
          open: true,
          message: message,
          level: 'error',
          duration: 5000
        })
      );

      if (e.code === 'PasswordResetRequiredException') {
        navigate(RESET_PASSWORD);
      }
    } finally {
      setLoading(false);
    }
  };

  const checkSession = async () => {
    try {
      const currentSession = await Auth.currentSession();
      if (currentSession) {
        navigate(PATIENT_SEARCH);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    checkSession();
  }, []);

  return (
    <PageTemplate simple>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Login</title>
      </Helmet>
      <div className={classes.container}>
        {/* <button onClick={testAPIBackend}>Test backend auth</button>
        <button onClick={testAPIBackendPublic}>Test backend public</button>
        <button onClick={logout}>Logout</button>
        <button onClick={() => login()}>Login</button> */}
        <form
          key="login"
          className={classes.formCtr}
          noValidate
          onSubmit={handleSubmit(login)}
        >
          <Typography variant="h1">Member Login</Typography>
          <Controller
            name="email"
            control={control}
            rules={{
              required: true,
              pattern: {
                value: emailRegex,
                message: 'Please enter a valid Email Address'
              }
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Email"
                variant="outlined"
                className={classes.txtField}
              />
            )}
          />
          {errors.email && (
            <InputError
              text={
                errors.email.message
                  ? errors.email.message
                  : 'Email is required'
              }
            />
          )}
          <Controller
            name="password"
            control={control}
            rules={{
              required: true
            }}
            render={({ field }) => (
              <TextField
                {...field}
                label="Password"
                variant="outlined"
                type="password"
                className={classes.txtField}
              />
            )}
          />
          {errors.password && (
            <InputError
              text={
                errors.password.message
                  ? errors.password.message
                  : 'Password is required'
              }
            />
          )}

          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submitBtn}
            disabled={loading}
          >
            {loading ? 'Signing in...' : 'Sign in'}
          </Button>
          <div className={classes.subBtns}>
            <Typography variant="body1" className={classes.formFooter}>
              No account yet? <a href="/signup">Click here to register</a>
            </Typography>
            <Typography variant="body1" className={classes.formFooter}>
              <a href="/reset-password">I forgot my password</a>
            </Typography>
          </div>
        </form>
      </div>
    </PageTemplate>
  );
};

export default withStyles(Login, styles);
