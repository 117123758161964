// @flow

export const log = (...args: any[]) => {
  if (
    process.env.REACT_APP_ENV !== 'production' ||
    process.env.REACT_APP_DEBUG === 'true'
  ) {
    console.log(...args.map((a) => a || ''));
  }
};

export const truncateString = (s: string = '', maxLength: number = 50) => {
  if (!s) return null;
  const showDots = s.length > maxLength;
  return `${s.substring(0, maxLength)}${showDots ? '...' : ''}`;
};

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

/* eslint-disable max-len */
export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const binaryToBlob = async (binaryData, contentType) => {
  let bytes = new Array(binaryData.length);

  for (let i = 0; i < binaryData.length; i++) {
    bytes[i] = binaryData.charCodeAt(i);
  }

  const data = new Uint8Array(bytes);

  return new Blob([data], { type: contentType });
};

export const calculateAvgRafUplift = (rafArr) => {
  if (!rafArr || !rafArr.length) return 0;

  const sumArr = (arr) => {
    let sum = 0;

    arr.forEach((raf) => {
      if (raf.rafScore) sum += raf.rafScore;
    });

    return sum;
  };

  let rafUplifts = [];

  rafArr.forEach((raf) => {
    const deltaRaf = sumArr(raf.deltaRafScore);

    rafUplifts.push(deltaRaf);
  });

  const avgRafUplift = (
    rafUplifts.reduce((pv, cv) => pv + cv, 0) / rafUplifts.length
  ).toFixed(3);

  console.log('rafUplifts: ', rafUplifts);
  console.log('avgRafUplift: ', avgRafUplift);

  return avgRafUplift;
};

export const decodeEntity = (inputStr) => {
  let textarea = document.createElement('textarea');
  textarea.innerHTML = inputStr;
  return textarea.value;
};

/**
 * @param {string} downloadFileName
 * @param {Blob} blob
 */
export const downloadFile = function (downloadFileName, blob) {
  // IE11 & Edge
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, downloadFileName);
  } else {
    // In FF link must be added to DOM to be clicked
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute('download', downloadFileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export const debounce = (func, delay) => {
  let timeoutId;
  return function (...args) {
    const context = this;
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(context, args);
    }, delay);
  };
};

const DEFAULT_REDIRECT_PATH = '/patients';

export const sanitizeRedirectOrURL = (redirect) => {
  try {
    if (!redirect) {
      return DEFAULT_REDIRECT_PATH;
    }
    const decodedRedirect = decodeURIComponent(redirect);
    const url = new URL(decodedRedirect, window.location.origin);

    if (url.origin === window.location.origin) {
      return url.pathname;
    }

    log('Invalid redirect detected: ', redirect);
    return DEFAULT_REDIRECT_PATH;
  } catch (error) {
    log('Malformed redirect URL: ', redirect, error);
    return DEFAULT_REDIRECT_PATH;
  }
};
