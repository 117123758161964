import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Auth } from 'aws-amplify';

const serverURL = process.env.REACT_APP_API_ENDPOINT;

const getNewJWTToken = async () => {
  const currentSession = await Auth.currentSession();
  const tok = currentSession.getIdToken().getJwtToken();
  return tok;
};

export const STACKS = {
  EHR: '/ehr',
  EHR2: '/ehr2'
};

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: `${serverURL}`,
    prepareHeaders: async (headers) => {
      const jwtToken = await getNewJWTToken();
      headers.set('Content-Type', 'application/json');
      headers.set('authorization', `Bearer ${jwtToken ? jwtToken : ''}`);
      if (process.env.REACT_APP_ENV === 'dev') {
        headers.set('x-mdp-email', 'test@email.com');
      }

      return headers;
    }
  }),
  endpoints: (builder) => ({
    getUser: builder.query({
      query: () => `${STACKS.EHR}/user`,
      transformResponse: (response) => {
        if (response.statusCode !== 200) throw new Error(response.body);
        return JSON.parse(response.body).data;
      }
    }),
    getOrganizations: builder.query({
      query: () => `${STACKS.EHR}/organization/all`,
      transformResponse: (response) => {
        const { organizations, currentOrganizationId } = JSON.parse(
          response.body
        );
        if (response.statusCode !== 200) throw new Error(response.body);
        const currentOrganization = organizations.find(
          (organization) => organization._id === currentOrganizationId
        );

        return { organizations, currentOrganization };
      }
    }),
    getPatientHCCs: builder.query({
      query: ({ patientId, searchString }) =>
        `${STACKS.EHR}/patient/${patientId}/hcc${searchString}`,
      transformResponse: (response) => {
        if (response.statusCode !== 200) throw new Error(response.body);
        return JSON.parse(response.body).data;
      }
    }),
    updateSourceFeedback: builder.mutation({
      query: ({ sourceId, agree, reason }) => ({
        url: `${STACKS.EHR}/patient/source/${sourceId}/feedback`,
        method: 'POST',
        body: {
          agree,
          reason
        }
      }),
      transformResponse: (response) => {
        return JSON.parse(response.body).success || false;
      }
    }),
    searchIcds: builder.query({
      query: ({ searchValue, cmsVersion }) =>
        `${STACKS.EHR}/icds?q=${searchValue}&cmsVersion=${cmsVersion}`,
      transformResponse: (response) => {
        if (response.statusCode !== 200) throw new Error(response.body);
        return JSON.parse(response.body).data;
      }
    }),
    searchCiInputs: builder.query({
      query: ({ searchValue, ciInputField }) =>
        `${STACKS.EHR}/searchCiInputs?q=${searchValue}&ciInputField=${ciInputField}`,
      transformResponse: (response) => {
        if (response.statusCode !== 200) throw new Error(response.body);
        return JSON.parse(response.body).data;
      }
    }),
    addPatientIcd: builder.mutation({
      query: ({ patientId, body }) => ({
        url: `${STACKS.EHR}/patient/${patientId}/add-icd`,
        method: 'POST',
        body
      }),
      transformResponse: (response) => {
        return JSON.parse(response.body);
      }
    }),
    addSourceToPatientHcc: builder.mutation({
      query: ({ body }) => ({
        url: `${STACKS.EHR}/addSourceToPatientHcc`,
        method: 'POST',
        body
      }),
      transformResponse: (response) => {
        return JSON.parse(response.body);
      }
    }),
    generatePatientSummary: builder.query({
      query: (patientId) => `${STACKS.EHR}/generatePatientSummary/${patientId}`,
      transformResponse: (response) => {
        console.log('[generatePatientSummary] response: ', response);
        if (response.statusCode !== 200) throw new Error(response.body);
        return JSON.parse(response.body).summaryText;
      }
    }),
    getIsUserHalBenchmarkOrg: builder.query({
      query: (userId) => `${STACKS.EHR}/getIsUserHalBenchmarkOrg/${userId}`,
      transformResponse: (response) => {
        console.log('[getIsUserHalBenchmarkOrg] response: ', response);
        if (response.statusCode !== 200) throw new Error(response.body);
        return JSON.parse(response.body);
      }
    }),
    addUserClinicConsent: builder.mutation({
      query: ({ body }) => ({
        url: `${STACKS.EHR}/userClinicConsent`,
        method: 'POST',
        body
      }),
      transformResponse: (response) => {
        return JSON.parse(response.body);
      }
    }),
    getPatientCareGaps: builder.query({
      query: (patientId) => `${STACKS.EHR}/patient/${patientId}/care-gaps`,
      transformResponse: (response) => {
        if (response.statusCode !== 200) throw new Error(response.body);
        return JSON.parse(response.body).data;
      }
    }),
    updateCareGaps: builder.mutation({
      query: ({ patientId, careGapId, status, note }) => ({
        url: `${STACKS.EHR}/patient/${patientId}/care-gaps/update`,
        method: 'POST',
        body: {
          careGapId,
          status,
          note
        }
      }),
      transformResponse: (response) => {
        return JSON.parse(response.body) || {};
      }
    }),
    getBenchmarkEvidenceForPatient: builder.mutation({
      query: ({ patientId, searchTerm }) => ({
        url: `${STACKS.EHR}/getHalBenchmarkEvidenceForPatient/${patientId}`,
        method: 'POST',
        body: {
          searchTerm
        }
      }),
      transformResponse: (response) => {
        return JSON.parse(response.body).evidence;
      }
    }),
    getPatientChartFiles: builder.query({
      query: ({ patientId, searchString }) => {
        const queryParams = searchString ? `?${searchString}` : '';
        return `${STACKS.EHR}/patient/${patientId}/document${queryParams}`;
      },
      transformResponse: (response) => {
        return JSON.parse(response.body);
      }
    }),
    uploadChartFiles: builder.mutation({
      query: ({ patientId, filename }) => ({
        url: `${STACKS.EHR2}/document/upload-url`,
        method: 'POST',
        body: { filename, patientId }
      }),
      transformResponse: (response) => {
        return JSON.parse(response.body);
      }
    }),
    uploadToPatient: builder.mutation({
      query: ({ fileIds }) => ({
        url: `${STACKS.EHR2}/document/enqueue-attempt-check-uploaded-chart-document`,
        method: 'POST',
        body: { fileIds }
      }),
      transformResponse: (response) => {
        return JSON.parse(response.body);
      }
    }),
    tryGetNewChartFiles: builder.mutation({
      query: ({ patientId, filenames }) => ({
        url: `${STACKS.EHR2}/document/chart-documents-exist`,
        method: 'POST',
        body: { patientId, filenames }
      }),
      transformResponse: (response) => {
        return JSON.parse(response.body);
      }
    })
  })
});

// Export hooks for usage in functional components
export const {
  useLazyGetUserQuery,
  useUpdateSourceFeedbackMutation,
  useGetOrganizationsQuery,
  useGetPatientHCCsQuery,
  useLazyGetPatientHCCsQuery,
  useLazySearchIcdsQuery,
  useLazySearchCiInputsQuery,
  useAddPatientIcdMutation,
  useAddSourceToPatientHccMutation,
  useLazyGeneratePatientSummaryQuery,
  useGetIsUserHalBenchmarkOrgQuery,
  useLazyGetIsUserHalBenchmarkOrgQuery,
  useAddUserClinicConsentMutation,
  useGetPatientCareGapsQuery,
  useUpdateCareGapsMutation,
  useGetBenchmarkEvidenceForPatientMutation,
  useGetPatientChartFilesQuery,
  useUploadChartFilesMutation,
  useUploadToPatientMutation,
  useTryGetNewChartFilesMutation
} = apiSlice;

// addSourceToPatientHcc
